export default {
  props: {
    body: {
      type: String,
      required: true,
    },
    media: {
      type: Object,
      required: true,
    },
  },
}
